
import { defineComponent, ref, watch, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import moment from 'moment'

export default defineComponent({
  props: ['course', 'showGroupCourseModel'],
  setup (props) {
    const visible = ref<boolean>(false)
    const gCourse = ref<any>()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const handleOk = () => {
      console.log('ok')
      visible.value = false
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    const formatDate = (value) => {
      return moment(value).format('DD/MM/YYYY')
    }
    watch(() => props.showGroupCourseModel, () => {
      visible.value = true
      console.log('props.course', props.course)
      gCourse.value = props.course
    })
    onMounted(() => {
      console.log('Mounted')
    })
    return {
      visible,
      handleCancel,
      handleOk,
      notify,
      gCourse,
      formatDate
    }
  }
})
