<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Matrix</a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="filter" type="flex" justify="end" :gutter="16">
        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <label for="">Candidates</label>
          <a-input-search
              v-model:value="selectedCandidate"
              placeholder="Candidate name or email"
              @search="filterData"
            />
        </a-col>
        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <label for="">Groups</label>
          <a-select v-model:value="selectedGroup" ref="select" placeholder="Group" clear>
            <a-select-option value="">All</a-select-option>
            <a-select-option :value="group.name" v-for="group in groups" :key="group._id">{{group.name}}</a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-if="matrixDatas.length > 0">
          <label for="">Courses</label>
          <a-select v-model:value="selectedCourse" mode="multiple" ref="select" placeholder="Course" clear>
            <a-select-option value="">All</a-select-option>
            <a-select-option :value="course._id" v-for="course in matrixDatas[0].courses" :key="course._id">{{course.title}}</a-select-option>
          </a-select>
        </a-col>
        <!--<a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <a-select v-model:value="selectedStatus" ref="select" placeholder="Status" clear>
            <a-select-option value="">All</a-select-option>
            <a-select-option :value="'Expired'">Expired</a-select-option>
            <a-select-option :value="'In Date'">In Date</a-select-option>
            <a-select-option :value="'Due in 30 days'">Due in 30 days</a-select-option>
            <a-select-option :value="'Not Started'">Not Started</a-select-option>
            <a-select-option :value="'Failed'">Failed</a-select-option>
          </a-select>
        </a-col>-->
        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
          <a-button type="primary" class="apply-filter" @click="filterData">Apply</a-button>
        </a-col>
      </a-row>
      <a-row class="header-status indicatores">
        <a-col :xs="24" :sm="24" :md="{ span: 16, offset: 8 }" :lg="{ span: 16, offset: 8 }" :xl="{ span: 16, offset: 8 }">
           <a-row type="flex" justify="end" :gutter="16" style="width: 100%">
            <a-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
              <ul>
                <li class="expireds"><span></span>Expired</li>
              </ul>
            </a-col>
            <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <ul>
                <li class="green"><span></span>In Date</li>
              </ul>
            </a-col>
            <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <ul>
                <li class="yellow"><span></span>Due</li>
              </ul>
            </a-col>
            <a-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
              <ul>
                <li class="grey"><span></span>Not Started</li>
              </ul>
            </a-col>
            <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <ul>
                <li class="blue"><span></span>Failed</li>
              </ul>
            </a-col>
           </a-row>
        </a-col>
      </a-row>
      <a-row class="matrix-Table">
        <div class="body main-content" v-if="!loader">
          <div class="panelc">
            <div class="CTable-wrapper table-scroll" id="table-scroll" >
              <div class="CTable-scroller">
                <table class="table trainelists table-bordered " v-if="datas.length > 0">
                  <thead>
                    <tr>
                      <th class="candidate cFixed header" scope="col">Candidate Name</th>
                      <th class="candidate header" scope="col">Group</th>
                      <th class="course_title header" scope="col" v-for="course in datas[0].courses" :key="course._id"> <div>{{course.title}}</div></th>
                    </tr>
                  </thead>
                    <tr v-for="data in datas" :key="data._id">
                      <th class="cFixed date-column body">
                        <div class="not-started">{{data.candidate.first_name}} {{data.candidate.last_name}}</div>
                      </th>
                      <td class="date-column body">
                        <div class="not-started">{{data.group?.group?.name}}</div>
                      </td>
                      <td v-for="course in data.courses" :key="course._id" class="date-column body">
                        <div v-if="course.type === 'Single'" class="course-type">
                          <div v-if="course.enroll && course.enroll.started && !course.certificate && course.total_chapter != course.total_chapter_read" class="not-started"></div>
                          <div v-if="course.enroll && !course.enroll.started" class="not-started"></div>
                          <div v-if="course.total_chapter === course.total_chapter_read && !course.certificate" class="failed"></div>
                          <div v-if="course.certificate && course.certificate.remaining_days > 30" class="in-date">{{formatDate(course.certificate.exp_date)}}</div>
                          <div v-if="course.certificate && course.certificate.remaining_days <= 30 && course.certificate.remaining_days > 0" class="in-30-date">{{formatDate(course.certificate.exp_date)}}</div>
                          <div v-if="course.certificate && course.certificate.remaining_days <= 0" class="expired">{{formatDate(course.certificate.exp_date)}}</div>
                        </div>
                        <div v-else class="course-type group-course">
                          <div class="not-started" @click="openGroupCourseModal(course)">Group Course</div>
                        </div>
                      </td>
                    </tr>
                </table>
              </div>
            </div>
            <div class="pagination">
              <a-radio-group @change="handlePageChange">
                <a-radio-button value="previous">Previous</a-radio-button>
                <a-radio-button value="next">Next</a-radio-button>
              </a-radio-group>
            </div>
          </div>
        </div>
      </a-row>
      <a-row class="matrix-Table">
        <a-col class="loader-data" :xs="24" :md="24" :xl="24" :xxl="24" v-if="loader">
          <div class="title">
            <h4>Please wait we are generating your matrix...</h4>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <GroupCourse :showGroupCourseModel="showGroupCourseModel" :course="gCourse" />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import moment from 'moment'

import profileService from '../../services/profile'
import searchService from '../../services/filter'
import groupService from '../../services/candidateGroup'
import commonServices from '../../services/common'

import GroupCourse from '@/components/client/matrix/groupCourse.vue'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    GroupCourse
  },
  setup () {
    const candidateName = ref<Array<any>>([])
    const columns = ref<Array<any>>([])
    const datas = ref<Array<any>>([])
    const matrixDatas = ref<Array<any>>([])
    const selectedStatus = ref<string>('')
    const selectedCandidate = ref<string>('')
    const selectedGroup = ref<string>('')
    const selectedCourse = ref<string>('')
    const groups = ref<Array<Group>>()
    const gCourse = ref<Array<any>>([])
    const showGroupCourseModel = ref<number>(0)
    const loader = ref<boolean>(false)
    const pageSize = ref<number>(10)
    const page = ref<number>(1)
    const openGroupCourseModal = (course) => {
      gCourse.value = course
      showGroupCourseModel.value++
    }
    const filterData = async () => {
      const profile = commonServices.getCurrentProfile()
      loader.value = true
      const responce = await profileService.getClientMatrix(profile._id, selectedCourse.value.toString(), { page: page.value, pageSize: pageSize.value, search: selectedCandidate.value })
      matrixDatas.value = responce.data
      datas.value = searchService.searchMatrix(matrixDatas.value, selectedGroup.value, selectedCourse.value, selectedStatus.value)
      loader.value = false
    }
    const formatDate = (value) => {
      return moment(value).format('DD/MM/YYYY')
    }
    const loadCss = async () => {
      setTimeout(() => {
        const getheight = window.getComputedStyle(
          document.querySelector('.trainelists .course_title')
        ).height
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.height = getheight
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.paddingLeft = '15px'
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.paddingTop = '0px'

        const mult = (datas.value[0].courses.length + 1) * 130
        document.querySelector<HTMLElement>(
          'table.trainelists'
        ).style.width = `${mult}px`
      }, 100)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getMatrix = async (config?) => {
      try {
        loader.value = true
        const clientId = commonServices.getClientId()
        const responce = await profileService.getClientMatrix(clientId, '', config)
        datas.value = responce.data
        console.log('datas.value', datas.value)
        matrixDatas.value = responce.data
        for (const resData of datas.value) {
          for (const course of resData.courses) {
            if (course.enroll) {
              if (course.enroll.expiry_date) {
                const currentDate = moment().format()
                const expDate = moment(course.enroll.expiry_date)
                const diff = expDate.diff(currentDate, 'days')
                course.enroll.available_days = diff
              } else {
                course.enroll.available_days = 'unlimited'
              }
            }
          }
        }
        loader.value = false
        loadCss()
      } catch (error) {
        loader.value = false
        await notify('Error', 'Something went wrong, please try again later', 'error')
      }
    }

    const handlePageChange = (event) => {
      if (page.value > 0) {
        if (event.target.value === 'next') {
          page.value++
        }
        if (event.target.value === 'previous') {
          page.value--
        }
        getMatrix({ page: page.value, pageSize: pageSize.value, search: selectedCandidate.value })
      }
    }

    onMounted(() => {
      getMatrix({ page: page.value, pageSize: pageSize.value, search: selectedCandidate.value })
      getGroups()
    })
    return {
      candidateName,
      columns,
      datas,
      filterData,
      moment,
      formatDate,
      matrixDatas,
      selectedStatus,
      selectedCandidate,
      selectedGroup,
      selectedCourse,
      groups,
      gCourse,
      openGroupCourseModal,
      showGroupCourseModel,
      loader,
      handlePageChange
    }
  }
})
</script>

<style lang="scss">
.user {
  .filter {
    .ant-col {
      text-align: left;
    }
    .ant-select {
      width: 100%;
    }
    .apply-filter {
      background: #FFC788;
      border-color: #FFC788;
      width: 100%;
      margin-top: 20px;
    }
  }
  .header-status {
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
    }
    .ant-select{
      width: 200px;
      text-align: left;
    }
  }
  .indicatores {
    .ant-row {
      width: 100%;
      background: #ffffff;
      padding: 15px;
    }
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      margin-bottom: 0;
      li {
        line-height: 20px;
        margin: 0 15px;
        font-size: 12px;
        font-weight: 600;
        color: #4f4f4f;
        span {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: block;
          float: left;
          margin: 0 10px 0 0;
        }
      }
      .expireds span {
        background: #F6596F;
      }
      .green span {
        background: #2ECC71;
      }
      .yellow span {
        background: #FFC788;
      }
      .grey span {
        background: #ffffff;
        border: 1px solid #A4A4A4;
      }
      .blue span {
        background: #11CDEF;
      }
    }
  }
  .matrix-Table {
    padding: 10px;
    .loader-data {
      background: #ffffff;
      .title {
        padding: 20px;
        font-size: 16px;
      }
    }
    .main-content{
      width: 100%;
    }
    .pagination {
      margin: 15px 0;
      display: flex;
      justify-content: flex-end;
    }
    table {
      background: #efefef;
      margin-bottom: 0rem;
      font-size: 14px;
      .header {
        background: #11CDEF;
        color: #ffffff !important;
        width: 130px;
        padding: 15px;
      }
      .body {
        border: 1px solid #A4A4A4 !important;
      }
    }
    th {
      border-radius: 0px !important;
      vertical-align: bottom;
      border: none !important;
      background-color: #ffffff;
      color: #A4A4A4;
      background: #efefef;
    }
    .candidate {
      vertical-align: middle;
    }
    .course_title {
      min-width: 100px;
      padding-bottom: 10px;
    }
    .date-column {
      vertical-align: middle;
      color: #ffffff;
    }
    .course-type {
      height: 100%;
      width: 100%;
    }
    .group-course {
      cursor: pointer;
    }
    .in-date {
      background: #2ac136;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .expired {
      background: #F6596F;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .in-30-date {
      background: #FFC788;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .failed {
      background: #11CDEF;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .not-started {
      background: #ffffff;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #A4A4A4;
      width: 100%;
    }
    .main-content .table-bordered th,
    .main-content .table-bordered td {
      border: 3px solid #ffffff !important;
      border-collapse: collapse;
      text-align: center;
      height: 70px;
    }
    .CTable-wrapper {
      position: relative;
    }
    .CTable-scroller {
      margin-left: 141px;
      overflow-x: scroll;
      overflow-y: visible;
      padding-bottom: 5px;
      width: calc(100% - 141px);
      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: #214678;
      }
    }
    .main-content .trainelists .cFixed {
      display: flex;
      align-items: center;
      left: 2px;
      position: absolute;
      top: auto;
      width: 142px;
      padding-left: 1px;
      border-bottom: 0px solid !important;
      height: 69px;
      margin:0
    }
    .panelc {
      background-color: #fff;
      padding: 10px 10px;
      border-radius: 10px;
    }
  }
}

</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #f7fafc;
}
</style>
